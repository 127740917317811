import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import "./MobileCarousel.css";

import { EffectCoverflow, Pagination } from "swiper";

import settingsApi from "../../api/config";

// import DocumentIcon from "../../assets/icons/mobileDoc.png";
import SeeMoreIcon from "@mui/icons-material/Visibility";

import { Grid } from "@mui/material";

import DocumentIcon from "../../assets/icons/documentsIcon.png";
import AudioIcon from "../../assets/icons/audioIcon.png";
import LinkIcon from "../../assets/icons/linkIcon.svg";
import LinkGlobeIcon from "@mui/icons-material/Language";
import UploadIcon from "@mui/icons-material/Download";

const MobileCarousel = ({ mediaLabel, media, selectedLang }) => {
  const renderContent = (mediaLabel, media) => {
    switch (mediaLabel) {
      case "images":
        return (
          <>
            {media &&
              media.map((image) => (
                <SwiperSlide key={image.id}>
                  <img
                    className="sliderImg"
                    src={`${settingsApi.BASE_URL}${image.url}`}
                    alt="media"
                  ></img>
                </SwiperSlide>
              ))}
          </>
        );
      case "videos":
        return (
          <>
            {media &&
              media.map((video) => (
                <SwiperSlide key={video.id}>
                  <video controls className="sliderImg">
                    <source
                      src={`${settingsApi.BASE_URL}${video.url}`}
                      alt="media"
                    />
                  </video>
                </SwiperSlide>
              ))}
          </>
        );

      case "links":
        return (
          <>
            {media &&
              media.map((doc) => (
                <SwiperSlide key={doc.id}>
                  <Grid container sx={{ justifyContent: "center" }}>
                    <Grid item lg={4} md={4} sm={4}>
                      <div className="sliderLinkContent">
                        <div style={{ maxHeight: "250px" }}>
                          <div key={doc.id} className="sliderContent">
                            <LinkGlobeIcon color="#391D69" fontSize="large" />
                            <div className="docInfo">
                              <p className="docName">{doc.urlDescription}</p>
                            </div>
                            <div className="linkIconContainer">
                              <img
                                src={LinkIcon}
                                className="linkIcon"
                                onClick={() => {
                                  window.open(`${doc.url}`, "__blank");
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </SwiperSlide>
              ))}
          </>
        );

      case "audio":
        return (
          <>
            {media &&
              media.map((doc) => (
                <SwiperSlide key={doc.id}>
                  <Grid container sx={{ justifyContent: "center" }}>
                    <Grid item lg={4} md={4} sm={4}>
                      <div className="sliderLinkContent">
                        <div style={{ maxHeight: "250px" }}>
                          <div key={doc.id} className="sliderContent">
                            <img src={AudioIcon} alt="empty state" />
                            <div className="docInfo">
                              <p className="docName">{doc.name}</p>
                              {/* <span>{selectedLang}</span> */}
                            </div>
                            <div className="linkIconContainer">
                              <UploadIcon
                                className="downloadIcon"
                                onClick={() => {
                                  window.open(
                                    `${settingsApi.BASE_URL}${doc.url}`,
                                    "__blank"
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </SwiperSlide>
              ))}
          </>
        );

      case "documents":
        return (
          <>
            {media &&
              media.map((doc) => (
                <SwiperSlide key={doc.id}>
                  <Grid container sx={{ justifyContent: "center" }}>
                    <Grid item lg={4} md={4} sm={4}>
                      <div className="sliderLinkContent">
                        <div style={{ maxHeight: "250px" }}>
                          <div key={doc.id} className="sliderContent">
                            <img src={DocumentIcon} alt="empty state" />
                            <div className="docInfo">
                              <p className="docName">{doc.name}</p>
                            </div>
                            <div className="linkIconContainer">
                              <UploadIcon
                                className="downloadIcon"
                                onClick={() => {
                                  window.open(
                                    `${settingsApi.BASE_URL}${doc.url}`,
                                    "__blank"
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </SwiperSlide>
              ))}
          </>
        );

      default:
        console.log("NÂO RECONHECEU ESSE VALOR");
    }
  };

  return (
    <div>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={true}
        modules={[EffectCoverflow, Pagination]}
        className="mobileCarousel"
      >
        {renderContent(mediaLabel, media)}
      </Swiper>
    </div>
  );
};

export default MobileCarousel;
